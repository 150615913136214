import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px;
  box-sizing: border-box;

  ${media.medium`
    justify-content: center;
  `}
`;

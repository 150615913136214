import styled from 'styled-components';

import Background from 'components/Background';

export default styled(Background)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'utils/detection';
import withParallax from 'utils/parallax';
import { hoverSpringMedium } from 'utils/motion';

import ProjectListItem from 'components/ProjectListItem';

const HoverItem = ({ className, isActive, ...restProps }) => {
  return (
    <motion.div
      className={className}
      animate={{ x: isActive ? -35 : 0 }}
      transition={hoverSpringMedium}
    >
      <ProjectListItem isActive={isActive} {...restProps} />
    </motion.div>
  );
}

export default styled(withParallax(HoverItem))`
  margin: 30px 0 0 0;

  &:first-child {
    margin: 0;
  }

  ${media.medium`
    position: relative;
    display: block;
    margin: 7vw 0 0 0;
    transform: translate3d(0, 0, 0);
    z-index: 1;

    &:first-child {
      margin: 0;
    }

    ${props => props.isActive && css`
      z-index: 0;
    `}
  `}
`;

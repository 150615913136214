import styled, { css } from 'styled-components';

export default styled.p`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.024rem;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  ${props => props.light && css`
    color: ${props => props.theme.textDark};
  `}
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.div`
  position: absolute;
  bottom: 130px;
  left: 40px;
  right: 60px;

  ${media.medium`
    bottom: auto;
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(0, -50%);
  `};
`;

import PropTypes from 'prop-types';

import LanguageModel from './Language';
import OgTagsModel from './OgTags';
import FooterModel from './Footer';
import LandingPageIntroSectionModel from './LandingPageIntroSection';
import LandingPageGenericSectionModel from './LandingPageGenericSection';
import LandingPageFeaturedProjectsSectionModel from './LandingPageFeaturedProjectsSection';
import PageLinkModel from './PageLink';

export default {
  id: PropTypes.number.isRequired,
  language: PropTypes.shape(LanguageModel).isRequired,
  ogTags: PropTypes.shape(OgTagsModel).isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.shape(PageLinkModel)).isRequired,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape(LandingPageIntroSectionModel),
    PropTypes.shape(LandingPageGenericSectionModel),
    PropTypes.shape(LandingPageFeaturedProjectsSectionModel),
  ])),
  footer: PropTypes.shape(FooterModel).isRequired,
};

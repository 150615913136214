import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';
import PageLinkModel from 'models/PageLink';

import Link from 'next/link';
import Wrapper from './styled/Wrapper';
import NavItem from './styled/NavItem';
import NavLink from './styled/NavLink';

export default class SectionsMenu extends PureComponent {


  static contextType = ThemeContext;

  render() {
    const { className, items } = this.props;

    return (
      <Wrapper className={className}>
        {items.sort((a,b) => a.idx-b.idx).map(({ name, url }) => (
          <NavItem key={name}>
            <Link href={url} passHref>
              <NavLink theme={this.context}>
                {name}
              </NavLink>
            </Link>
          </NavItem>
        ))}
      </Wrapper>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';

import Wrapper from './styled/Wrapper';
import Line from './styled/Line';
import Label from './styled/Label';

export default class ScrollPrompt extends PureComponent {

  static defaultProps = {
    label: 'scroll to find out more'
  };

  static contextType = ThemeContext;

  render() {
    const { className, label } = this.props;

    return (
      <Wrapper className={className}>
        <Line theme={this.context} />
        <Label theme={this.context}>{label}</Label>
      </Wrapper>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';
import FooterModel from 'models/Footer';

import Wrapper from './styled/Wrapper';
import LogoWrapper from './styled/LogoWrapper';
import Logo from './styled/Logo';
import Unit9Company from './styled/Unit9Company';
import SocialLinks from './styled/SocialLinks';
import SocialLink from './styled/SocialLink';
import InfoWrapper from './styled/InfoWrapper';
import Info from './styled/Info';

export default class Footer extends PureComponent {

  static contextType = ThemeContext;

  onUnit9Click = () => {
    // if(appState.analytics)appState.analytics._trackEvent('Footer', 'click link', 'UNIT9 Company');
  };

  onSocialLinkClick = type => {
    // if(appState.analytics)appState.analytics._trackEvent('Footer', 'click link', `social: ${type}`);
  };

  render() {
    const { className, contactInfo, socialLinks } = this.props;

    const contactInfoHTML = contactInfo.replace(/\n/g, '<br />');

    return (
      <Wrapper className={className} theme={this.context}>
        <LogoWrapper>
          <Logo />
          <Unit9Company
            theme={this.context}
            href="https://unit9.com"
            target="_blank"
            onClick={this.onUnit9Click}
          >
            A UNIT9 Company
          </Unit9Company>
        </LogoWrapper>
        <InfoWrapper>
          <Info dangerouslySetInnerHTML={{ __html: contactInfoHTML }} theme={this.context} />
        </InfoWrapper>
        <SocialLinks>
          {socialLinks.map(link => (
            <SocialLink key={link.name} theme={this.context}>
              <a href={link.url} target="_blank" onClick={() => this.onSocialLinkClick(link.name)}>
                {link.name}
              </a>
            </SocialLink>
          ))}
        </SocialLinks>
      </Wrapper>
    );
  }
}

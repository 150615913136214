import styled from 'styled-components';

import BurgerMenu from 'components/BurgerMenu';

export default styled(BurgerMenu)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

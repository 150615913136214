import styled, { css } from 'styled-components';

export default styled.button`
  background: none;
  border: 0;
  outline: 0;
  /* color: ${props => props.theme.textLight}; */
  color: #658096;
  font-family: 'Galano Classic';
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  ${props => props.light && css`
    /* color: ${props => props.theme.textDark}; */
  `}
`;

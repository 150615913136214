import styled from 'styled-components';

export default styled.p`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 24.34px;
  padding: 0;
  margin: -2px 0 0 14px
`;

import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  sha256: PropTypes.string.isRequired,
  ext: PropTypes.string.isRequired,
  mime: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

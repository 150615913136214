import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.ul`
  list-style: none;
  margin: 0 0 65px 0;
  padding: 0;

  ${media.medium`
    position: fixed;
    top: 44px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  `}
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import TopButton from 'components/TopButton';

export default styled(TopButton)`
  display: none;

  ${media.medium`
    display: block;
    position: fixed;
    bottom: 28px;
    right: 24px;
    transform-origin: 100% 50%;
    transform: rotate(-90deg) translate(100%, -50%);
    transition-property: opacity, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    z-index: 1;

    ${props => props.hidden && css`
      display: block;
      opacity: 0;
      visibility: hidden;
    `}
  `}
`;

import PropTypes from 'prop-types';

import ProjectModel from './Project';

export default {
  id: PropTypes.number.isRequired,
  __component: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      project: PropTypes.shape(ProjectModel)
    })
  ).isRequired,
};

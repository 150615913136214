import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import Logo from 'components/Logo';

export default styled(Logo)`
  width: 138px;
  height: 64px;

  ${media.medium`
    width: 103px;
    height: 48px;

    path {
      fill: #d7fffc;
    }
  `};
`;

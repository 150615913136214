import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';
import LandingPageFeaturedProjectsSectionModel from 'models/LandingPageFeaturedProjectsSection';

import Wrapper from './styled/Wrapper';
import ProjectListItem from './styled/ProjectListItem';

export default class FeaturedProjectsSection extends PureComponent {


  static contextType = ThemeContext;

  state = {
    activeProject: null,
  };

  onMouseEnter = project => {
    this.setState({ activeProject: project });
  };

  onMouseLeave = () => {
    this.setState({ activeProject: null });
  };

  render() {
    const { projects } = this.props;
    const { activeProject } = this.state;

    return (
      <Wrapper>
        {projects.map(({ project }) => (
          <ProjectListItem
            key={project.slug}
            project={project}
            isActive={activeProject === project}
            isInactive={activeProject !== null && activeProject !== project}
            onMouseEnter={() => this.onMouseEnter(project)}
            onMouseLeave={this.onMouseLeave}
            baseLink="/projects"
          />
        ))}
      </Wrapper>
    );
  }
}

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

import SectionsMenu from '../components/SectionsMenu';

export default styled(SectionsMenu)`
  display: none;

  ${media.medium`
    display: flex;
    position: fixed;
    top: 30px;
    left: 0;
    transform: translate(0, 0);
    z-index: 1;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out-quad;

    ${props => props.hidden && css`
      display: flex;
      transform: translate(0, -40px);
      opacity: 0;
      visibility: hidden;
    `}
  `}
`;

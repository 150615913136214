import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  background: transparent;
  padding: 40px;
  box-sizing: border-box;

  ${media.medium`
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  `}
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import SectionName from 'components/SectionName';

export default styled(SectionName)`
  display: none;

  ${media.medium`
    display: block;
    position: fixed;
    left: 25px;
    bottom: 5px;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;

    ${props => props.hidden && css`
      opacity: 0;
      visibility: hidden;
    `}
  `}
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import withParallax from 'utils/parallax';

export default styled(withParallax(styled.h1`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
  padding: 0;
  margin: 0;

  ${media.medium`
    font-size: 5vw;
    text-align: left;
    padding: 1vw 0 0 25%;

    ${props => props.isLight && css`
      color: ${props => props.theme.textDark};
    `}
  `}
`))`
  margin: 0 0 42px 0;

  ${media.medium`
    flex: 1;
    order: 0;
  `}
`;

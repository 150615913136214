import styled from 'styled-components';

import { media } from 'utils/detection';
import withParallax from 'utils/parallax';
import LinkMore from 'components/LinkMore';

export default styled(withParallax(styled(LinkMore)`
`))`
  margin-top: 46px;

  ${media.medium`
    margin-top: 28px;
  `}
`;

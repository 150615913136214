import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';
import { sizes } from 'utils/detection';
import LandingPageGenericSectionModel from 'models/LandingPageGenericSection';

import Wrapper from './styled/Wrapper';
import Title from './styled/Title';
import Column1 from './styled/Column1';
import Column1Heading from './styled/Column1Heading';
import Column1Body from './styled/Column1Body';
import Column2 from './styled/Column2';
import Column2Body from './styled/Column2Body';
import LinkMore from './styled/LinkMore';
import ContentWrapper from './styled/ContentWrapper';

export default class GenericSection extends PureComponent {


  static contextType = ThemeContext;

  state = {
    isMedium: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ isMedium: window.innerWidth >= sizes.medium });
  };

  render() {
    const {
      name,
      heading,
      column1Heading,
      column1Text,
      column2Text,
      linkLabel,
      pageLink,
      isInverse,
    } = this.props;

    const { isMedium } = this.state;

    return (
      <Wrapper>
        <ContentWrapper>
          <Title theme={this.context} isLight={isInverse}>
            {heading}
          </Title>
          <Column1 reverse={!column1Heading && !column1Text}>
            {column1Heading && (
              <Column1Heading theme={this.context} isLight={isInverse}>
                {column1Heading}
              </Column1Heading>
            )}
            {column1Text && (
              <Column1Body
                theme={this.context}
                isLight={isInverse}
                dangerouslySetInnerHTML={{ __html: column1Text.replace(/\n/g, '<br/>') }}
              />
            )}
            {!column2Text && (
              <LinkMore label={linkLabel} link={pageLink.url} isLight={isInverse && isMedium} />
            )}
          </Column1>
          <Column2 theme={this.context}>
            {column2Text && (
              <Column2Body
                theme={this.context}
                isLight={isInverse}
                dangerouslySetInnerHTML={{ __html: column2Text.replace(/\n/g, '<br/>') }}
              />
            )}
            {column2Text && (
              <LinkMore label={linkLabel} link={pageLink.url} isLight={isInverse && isMedium} />
            )}
          </Column2>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

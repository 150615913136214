import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import { ThemeContext } from 'utils/theme';
import { sizes } from 'utils/detection';
import LandingPageModel from 'models/LandingPage';
import PageTransition from 'containers/PageTransition';
import Head from 'components/Head';
import SlidingPageTransition from 'components/SlidingPageTransition';
import { BACKGROUND_STATE } from 'components/Background/constants';

import Background from './styled/Background';
import SectionWrapper from './styled/SectionWrapper';
import IntroSection from './styled/IntroSection';
import GenericSection from './styled/GenericSection';
import FeaturedProjectsSection from './styled/FeaturedProjectsSection';
import BurgerButton from './styled/BurgerButton';
import BurgerMenu from './styled/BurgerMenu';
import SectionName from './styled/SectionName';
import Footer from './styled/Footer';
import SectionsMenu from './styled/SectionsMenu';
import ContentWrapper from './styled/ContentWrapper';
import TopButton from './styled/TopButton';
import ScrollPrompt from './styled/ScrollPrompt';

export default class HomePage extends PureComponent {

  static defaultProps = {
    burgerOffset: 100,
    eyeLineDown: 0.6,
    eyeLineUp: 0.4,
  };

  static contextType = ThemeContext;

  static computeSectionBounds = el => {
    const bounds = el.getBoundingClientRect();
    return {
      top: bounds.top + window.pageYOffset,
      bottom: bounds.bottom + window.pageYOffset,
    };
  };

  static getSectionComponent(type) {
    switch (type) {
      case "ComponentPageIntroSection":
        return IntroSection;
      case "ComponentLandingPageSectionGenericSection":
        return GenericSection;
      case "ComponentLandingPageSectionFeaturedProjectSection":
        return FeaturedProjectsSection;
      default:
        return null;
    }
  }

  state = {
    isMenuVisible: false,
    isBackgroundLight: false,
    isBackgroundLogoVisible: true,
    isBurgerVisible: false,
    backgroundState: BACKGROUND_STATE.INTRO,
    currentSectionName: '',

  };


  sections = [];
  lastScrollY = -1;

  componentDidMount() {
    const { router } = this.props;

    this.onScroll(0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    this.sections = [];
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  }

  refSection = (el, name, isInverse) => {
    if (el) {
      this.sections.push({ el, name, isInverse, bounds: HomePage.computeSectionBounds(el) });
    } else {
      this.sections = this.sections.filter(item => item.name !== name);
    }
  };

  updateActiveSection(scrollY) {
    const { eyeLineDown, eyeLineUp } = this.props;

    let activeSection = this.sections[0];

    const isMedium = window.innerWidth >= sizes.medium;
    const isScrollingDown = scrollY > this.lastScrollY;
    const eyeLineRatio = isScrollingDown ? eyeLineDown : eyeLineUp;
    const eyeLine = window.innerHeight * eyeLineRatio + scrollY;
    this.lastScrollY = scrollY;

    for (let i = 0; i < this.sections.length; ++i) {
      const bounds = this.sections[i].bounds;
      if (bounds.top < eyeLine) {
        activeSection = this.sections[i];
      }
    }

    this.setState({
      currentSectionName: activeSection ? activeSection.name : '',
      isBackgroundLight: activeSection ? (activeSection.isInverse && isMedium) : false,
    });
  }

  onBurgerClick = () => {
    const { isMenuVisible } = this.state;
    this.setState({ isMenuVisible: !isMenuVisible });
    // if(appState.analytics)appState.analytics._trackEvent('LandingPage', 'click burger');
  };

  onTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // if(appState.analytics)appState.analytics._trackEvent('LandingPage', 'click top');
  };

  onScroll = throttle(() => {
    const scrollY = window.pageYOffset;
    const { burgerOffset } = this.props;
    const { isBackgroundLogoVisible } = this.state;

    const isBurgerVisible = scrollY > burgerOffset;
    let backgroundState = isBackgroundLogoVisible
      ? BACKGROUND_STATE.NORMAL
      : BACKGROUND_STATE.CLEAN;

    this.setState({ isBurgerVisible, backgroundState });
    this.updateActiveSection(scrollY);
  }, 1000 / 10);

  onResize = () => {
    this.sections = this.sections.map(item => ({
      ...item,
      bounds: HomePage.computeSectionBounds(item.el),
    }));
    this.onScroll();
  };

  renderSections(sections) {
    const { navLinks } = this.props;

    return sections.map(section => {
      const ComponentClass = HomePage.getSectionComponent(section.__typename);

      if (ComponentClass) {
        return (
          <SectionWrapper
            key={`${section.id}-${section.name}`}
            ref={el => this.refSection(el, section.name, section.isInverse)}
          >
            {React.createElement(ComponentClass, {
              ...section,
              navLinks,
            })}
          </SectionWrapper>
        );
      }

      console.warn(`No section found for type ${section.__component}`);
      return null;
    });
  }

  render() {
    const { router, ogTags, navLinks, sections, footer } = this.props;
    const {
      isMenuVisible,
      isBackgroundLight,
      isBurgerVisible,
      backgroundState,
      currentSectionName,
    } = this.state;

    return (
      <>
        <Head ogTags={ogTags} router={router} />
        <PageTransition router={router} component={SlidingPageTransition}>
          <Background
            light={isBackgroundLight && !isMenuVisible}
            state={backgroundState}
          />

          <ContentWrapper hidden={isMenuVisible}>
            {this.renderSections(sections)}
            <Footer {...footer} />
          </ContentWrapper>

          <SectionsMenu hidden={isBurgerVisible} items={navLinks} />
          <ScrollPrompt hidden={isBurgerVisible} />
          <TopButton
            light={isBackgroundLight}
            hidden={!isBurgerVisible || isMenuVisible}
            onClick={this.onTopClick}
          />

          {sections.map(({ id, name }) => (
            <SectionName
              key={`${id}-${name}`}
              name={name}
              light={isBackgroundLight}
              hidden={isMenuVisible || name !== currentSectionName || !currentSectionName}
            />
          ))}

          <BurgerMenu router={router} items={navLinks} light={false} hidden={!isMenuVisible} />
          <BurgerButton
            isActive={isMenuVisible}
            light={isBackgroundLight && !isMenuVisible}
            hidden={!isBurgerVisible}
            onClick={this.onBurgerClick}
          />
        </PageTransition>
      </>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';
import LandingPageIntroSectionModel from 'models/LandingPageIntroSection';
import PageLinkModel from 'models/PageLink';

import Wrapper from './styled/Wrapper';
import SectionsMenu from './styled/SectionsMenu';
import Logo from './styled/Logo';
import Message from './styled/Message';

export default class IntroSection extends PureComponent {


  static contextType = ThemeContext;

  render() {
    const { text, navLinks } = this.props;

    return (
      <Wrapper>
        <SectionsMenu items={navLinks} />
        <Logo color={this.context.secondary} />
        <Message theme={this.context} dangerouslySetInnerHTML={{ __html: text }} />
      </Wrapper>
    );
  }
}

import styled from 'styled-components';

import { media } from 'utils/detection';
import withParallax from 'utils/parallax';

// const motionP = withParallax(styled.p``);

export default styled(withParallax(styled.p`
  padding: 0;
  margin: 0;
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;

  ${media.medium`
    font-size: 2.8vw;
    font-weight: 400;
    line-height: 4vw;
  `}
`))`
  width: calc(50% + 40px);
  align-self: flex-end;
  margin: 0 0 30px 0;

  ${media.medium`
    position: absolute;
    top: calc(50% - 76px);
    left: 50%;
    width: calc(50% - 90px);
  `}

  ${media.large`
    top: calc(50% - 98px);
    width: calc(50% - 120px);
  `}
`;

import styled from 'styled-components';

import { media } from 'utils/detection';
import BurgerButton from 'components/BurgerButton';

export default styled(BurgerButton)`
  position: fixed;
  top: 24px;
  right: 20px;
  z-index: 2;

  ${media.medium`
    top: 30px;
  `}
`;

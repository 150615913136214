import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

import Footer from 'components/Footer';

export default styled(Footer)`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;

  ${media.medium`
    height: 214px;
  `};
`;

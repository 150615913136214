import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import ScrollPrompt from 'components/ScrollPrompt';

export default styled(ScrollPrompt)`
  display: none;

  ${media.medium`
    display: flex;
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translate3d(0, 0, 0);
    transition-property: opacity, visibility, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out-quad;

    ${props => props.hidden && css`
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, -40px, 0);
    `}
  `}
`;

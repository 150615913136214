import React, { useRef, useState, useEffect } from 'react';
import { useMotionValue, useScroll, useTransform, useSpring, motion } from 'framer-motion';

export default function withParallax(WrappedComponent, range = 100, margin = 50) {
  return function ParallaxItem({ className, ...restProps }) {
    const ref = useRef();
    const { scrollY } = useScroll();
    const isParallaxActive = useMotionValue(1);
    const isReady = useMotionValue(false);
    const boundTop = useMotionValue(0);
    const boundBottom = useMotionValue(0);
    const windowHeight = useMotionValue(0);

    const transformScroll = value => {
      if (!isReady.get() || !ref.current) {
        return 0;
      }

      const thresholdTop = boundBottom.get() - margin;
      const thresholdBottom = boundTop.get() - windowHeight.get() + margin;

      if (value > thresholdTop) {
        // setIsParallaxActive(false);
        isParallaxActive.set(1);
        return -range;
      } else if (value < thresholdBottom) {
        // setIsParallaxActive(false);
        isParallaxActive.set(0);
        return range;
      }

      isParallaxActive.set(1);
      return 0;
    };

    const onResize = () => {
      if (!ref.current) {
        return null;
      }

      setTimeout(() => {
        const bounds = ref.current.getBoundingClientRect();
        const domBounds = {
          top: bounds.top + window.pageYOffset,
          bottom: bounds.bottom + window.pageYOffset,
        };

        boundTop.set(domBounds.top);
        boundBottom.set(domBounds.bottom);
        windowHeight.set(window.innerHeight);
        isReady.set(true);

        setTimeout(() => window.dispatchEvent(new CustomEvent('scroll')));
      });
    };

    useEffect(() => {
      if (!ref.current) {
        return null;
      }

      onResize();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [ref]);

    const y = useSpring(useTransform(scrollY, transformScroll), {
      damping: 100,
      stiffness: 100,
      mass: 3,
    });

    const opacity = useTransform(y, value => 1 - Math.abs(value) / range);

    return (
      <div className={className} ref={ref}>
        <motion.div initial={{ y: 0, opacity: 1 }} style={{ y , opacity}}>
          <WrappedComponent
            isParallaxed={true}
            isParallaxActive={isParallaxActive}
            {...restProps}
          />
      </motion.div>
      </div>
    );
  };
}

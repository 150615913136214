import PropTypes from 'prop-types';

import LanguageModel from './Language';
import SocialLinkModel from './SocialLink';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  contactInfo: PropTypes.string.isRequired,
  language: PropTypes.shape(LanguageModel).isRequired,
  socialLinks: PropTypes.arrayOf(PropTypes.shape(SocialLinkModel)).isRequired,
};

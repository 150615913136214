import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.a`
  display: block;
  color: ${props => props.theme.textDark};
  font-family: 'Galano Classic';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1.17px;
  margin: 21px 0 0 0;
  padding: 0;
  text-decoration: none;

  ${media.medium`
    color: ${props => props.theme.textLight};
    font-size: 9px;
    letter-spacing: 0.75px;
    margin: 19px 0 0 0;
  `};
`;

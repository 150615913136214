import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.p`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 16px;
  font-weight: 400;
  line-height: 23.33px;
  margin: 0;

  a {
    color: ${props => props.theme.textLight};
  }

  ${media.medium`
    font-size: 1.4vw;
    line-height: 2.4vw;

    ${props =>
      props.isLight &&
      css`
        color: ${props => props.theme.textDark};

        a {
          color: ${props => props.theme.textDark};
        }
      `}
  `}

  ${media.large`
    font-size: 1.1vw;
    line-height: 2vw;
  `}
`;

import { GetStaticProps } from 'next';
import React from 'react';

import HomePage from 'containers/HomePage';
// import { getLandingPage } from 'utils/api';
import { queryCMS } from 'utils/cms/api';
import { footerQuery, landingPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';

const Component = props => {
  return <HomePage {...props} />;
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const landingPageResults = await queryCMS({
    query: landingPageQuery({ locale }),
  });
  const footerResults = await queryCMS({
    query: footerQuery({ locale }),
  });

  return {
    props: {
      ...landingPageResults.landingPage,
      footer: footerResults.footer,
    },
    revalidate: ISR_TIMEOUT,
  };
};

export default Component;

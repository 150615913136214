import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.ul`
  list-style-type: none;
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  ${media.medium`
    display: block;
    bottom: auto;
    right: auto;
    left: 75%;
    top: 50%;
    transform: translate(0, -50%);
  `};
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';
import withParallax from 'utils/parallax';

export default styled(withParallax(styled.article`
  margin: 0;
  padding: 0;

  ${media.medium`
    padding: 0 35px;
  `}
`))`
  ${media.medium`
    flex: 0.5;
    order: 1;

    ${props => props.reverse && css`
      order: 3;
    `}
  `}
`;

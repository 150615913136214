import styled from 'styled-components';


import { media } from 'utils/detection';

export default styled.a`
  position: relative;
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 1.23rem;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;

  ${media.medium`
    opacity: 0.95;
    font-size: 1rem;
  `}
`;

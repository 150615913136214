import styled from 'styled-components';

import { media } from 'utils/detection';
import withParallax from 'utils/parallax';
import Logo from 'components/Logo';

export default styled(withParallax(Logo))`
  width: 220px;
  height: 93px;
  margin: 0 0 67px 0;

  ${media.medium`
    width: 297px;
    height: 133px;
    position: absolute;
    top: 50%;
    left: 25%;
    margin-left: -148.5px;
    margin-top: -66.5px;
  `}

  ${media.large`
    width: 374px;
    height: 173px;
  `}
`;

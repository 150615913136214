import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.div`
  background-color: ${props => props.theme.secondary};

  ${media.medium`
    background-color: ${props => props.theme.bg2};
  `};
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.p`
  color: ${props => props.theme.textDark};
  font-family: 'Galano Classic';
  font-size: 16px;
  font-weight: 400;
  line-height: 26.67px;

  a {
    color: ${props => props.theme.textDark};
    text-decoration: underline;
  }

  ${media.medium`
    color: ${props => props.theme.textLight};
    font-size: 0.7rem;
    line-height: 1.1rem;

    a {
      color: ${props => props.theme.textLight};
    }
  `};
`;

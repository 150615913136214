import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.article`
  margin: 40px 0 0 0;

  ${media.medium`
    flex: 0.5;
    padding: 0 40px 0 35px;
    margin: 0;
    order: 2;
  `}
`;

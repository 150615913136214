import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';

import Label from './styled/Label';

export default class TopButton extends PureComponent {

  static defaultProps = {
    label: 'top',
    light: false,
  };

  static contextType = ThemeContext;

  render() {
    const { className, label, light, ...restProps } = this.props;

    return (
      <Label className={className} theme={this.context} light={light} {...restProps}>{label}</Label>
    );
  }
}

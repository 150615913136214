import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.h2`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 19px 0;

  ${media.medium`
    font-size: 2vw;
    line-height: 3.2vw;

    ${props => props.isLight && css`
      color: ${props => props.theme.textDark};
    `}
  `}
`;

import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

import SectionsMenu from '../../SectionsMenu';

export default styled(SectionsMenu)`
  position: relative;
  width: calc(50% + 40px);
  align-self: flex-end;

  ${media.medium`
    display: none;
  `}
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'utils/detection';
import { hoverSpringSmall } from 'utils/motion';

export default styled(({ className, children }) => {
  return (
    <motion.li
      className={className}
      whileHover={{x: -5}}
      transition={hoverSpringSmall}
    >
      {React.Children.toArray(children)}
    </motion.li>
  );
})`
  float: left;
  font-family: 'Galano Classic';
  font-size: 13px;
  font-weight: 400;

  a {
    color: ${props => props.theme.textDark};
    text-decoration: none;
  }

  ${media.medium`
    float: unset;
    display: block;
    font-size: 0.9rem;

    a {
      color: ${props => props.theme.textLight};
    }
  `};
`;

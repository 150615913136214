import PropTypes from 'prop-types';

import UploadedImageModel from './UploadedImage';

export default {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.shape(UploadedImageModel).isRequired,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled/Wrapper';
import Label from './styled/Label';

export default class SectionName extends PureComponent {

  static defaultProps = {
    name: 'Featured Works',
    light: false,
  };

  render() {
    const { className, name, light, ref2, ...restProps } = this.props;

    return (
      <Wrapper className={className} ref={ref2} {...restProps}>
        <Label theme={this.context} light={light}>{name}</Label>
      </Wrapper>
    );
  }
}

import styled from 'styled-components';

import { media } from 'utils/detection';

export default styled.div`
  padding: 40px;
  box-sizing: border-box;
  margin: 0;

  ${media.medium`
    margin: 0px 0px 0px calc(12.5% - 20px);
  `}
`;

import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import PropTypes from 'prop-types';

import PageLinkModel from './PageLink';

export default {
  id: PropTypes.number.isRequired,
  __component: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  heading: PropTypes.string,
  column1Heading: PropTypes.string,
  column1Text: PropTypes.string,
  column2Text: PropTypes.string,
  linkLabel: PropTypes.string.isRequired,
  pageLink: PropTypes.shape(PageLinkModel).isRequired,
  isInverse: PropTypes.bool,
};

import styled from 'styled-components';
import { motion } from 'framer-motion';

export default styled(({ className, ...restProps }) => {
  return (
    <motion.li className={className} whileHover={{ x: -10 }} {...restProps} />
  );
})`
  display: block;
  margin: 15px 0;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;
